<template>
  <div class="events">
    <div class="padding_top"></div>

    <b-container>
      <b-row class="page_header" align-h="center">
        <b-col cols="auto">
          <h1 class="page_title">
            <span class="accent_color">BK</span> Мероприятия
          </h1>
          <p class="page_subtitle">Регистрации</p>
        </b-col>
      </b-row>

      <b-row v-if="events">
        <b-col cols="12" md="4" v-for="(event, index) in events" :key="index">
          <EventCard
            :event="event"
            buttonText="регистрации"
            :index="index"
            admin
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EventCard from "@/components/events/EventCard.vue";
import EventModal from "@/components/events/EventModal.vue";

export default {
  components: {
    EventCard,
    EventModal,
  },
  methods: {
    ...mapActions(["getEvents", "getMyEvents", "getUser"]),
  },
  computed: {
    ...mapState({
      events: (state) => state.events.events,
      myEvents: (state) => state.events.myEvents,
      view: (state) => state.events.view,
    }),
  },
  async mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "Login" });
    } else {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getUser();
        await this.getEvents();
        await this.getMyEvents();

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    }
  },
};
</script>
